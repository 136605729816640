<template>
  <div
    v-if="loading"
    class="spinner">
    <div
      class="spinner-border text-info"
      role="status" />
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(#000, 0.2);
  z-index: 1001;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
