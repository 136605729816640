<template>
  <section
    id="scrollspyLocation"
    class="section section-map bg-light">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <GmapMap
            class="g-map lazyload"
            :center="gMapPosition"
            :zoom="gMap.zoom"
            :map-type-id="gMap.typeId">
            <GmapMarker :position="gMap.center" />
          </GmapMap>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionMap',
  data() {
    return {
      gMapPosition: { lat: 43.188, lng: 17.6783799 },
      gMap: {
        center: { lat: 43.1864271, lng: 17.6783799 },
        zoom: 16,
        typeId: 'roadmap',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.section-map {
  padding-top: 20px;
  padding-bottom: 40px;
}

.g-map {
  height: 500px;
  filter: grayscale(100%) invert(10%) contrast(100%);
}
</style>
