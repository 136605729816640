import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBed,
  faUtensils,
  faCoffee,
  faPlane,
  faWifi,
  faCar,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faBed,
  faUtensils,
  faCoffee,
  faPlane,
  faWifi,
  faCar,
);
