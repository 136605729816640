<template>
  <nav
    id="navbar"
    class="navbar sticky-top navbar-light navbar-expand-lg bg-light">
    <div class="container">
      <a href="#" class="navbar-brand">
        <!-- <img src="@/assets/logo.png" alt="Pansion FIlipovic Logo" height="60"> -->
        <span class="logo-text">Pansion MILENA FILIPOVIC</span>
        <div class="icons-wrapper">
          <i
            v-for="i in 3"
            :key="i"
            :style="{ 'background-image': `url(${require('@/assets/star.svg')})` }"
            class="icon-star" />
        </div>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon" />
      </button>
      <div id="navbarSupportedContent" class="collapse navbar-collapse">
        <ul class="navbar-nav ms-auto">
          <li
            v-for="(nav, i) in navItems"
            :key="i"
            class="nav-item"
            @click="scrollIntoView(nav.scrollSpy, i)">
            <span
              class="nav-link"
              :class="{ 'active': selectedItem === i }">{{ nav.item }}</span>
          </li>
          <li class="nav-item dropdown">
            <a
              id="navbarDropdown"
              class="nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              {{ selectedLanguageTitle }}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li
                v-for="lang in otherLanguages"
                :key="lang.key">
                <span
                  class="dropdown-item"
                  @click="selectLanguage(lang)">
                  {{ lang.title }}
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import ls from '@/services/ls';

export default {
  name: 'MainHeader',
  data() {
    return {
      selectedItem: 0,
      selectedLanguage: this.$i18n.locale,
      languages: [{
        key: 'hr',
        title: 'HR',
      }, {
        key: 'en',
        title: 'EN',
      }, {
        key: 'de',
        title: 'DE',
      }, {
        key: 'it',
        title: 'IT',
      }],
    };
  },
  computed: {
    navItems() {
      return [{
        item: this.$t('global.home'),
        scrollSpy: '#scrollspyAbout',
      }, {
        item: this.$t('global.accomodation'),
        scrollSpy: '#scrollspyAccomodation',
      }, {
        item: this.$t('global.gallery'),
        scrollSpy: '#scrollspyGallery',
      }, {
        item: this.$t('global.contact'),
        scrollSpy: '#scrollspyContact',
      }, {
        item: this.$t('global.location'),
        scrollSpy: '#scrollspyLocation',
      }];
    },
    selectedLanguageTitle() {
      return this.languages.find((lang) => lang.key === this.selectedLanguage).title;
    },
    otherLanguages() {
      return this.languages.filter((lang) => lang.key !== this.selectedLanguage);
    },
  },
  mounted() {
    this.setHtmlLang();
  },
  methods: {
    selectLanguage(lang) {
      this.selectedLanguage = lang.key;
      this.$i18n.locale = lang.key;
      ls.setLanguage(lang.key);
      this.setHtmlLang();
    },
    setHtmlLang() {
      const html = document.documentElement;
      html.setAttribute('lang', this.selectedLanguage);
    },
    scrollIntoView(id, index) {
      this.selectedItem = index;
      const el = document.getElementById(id.replace('#', ''));
      if (!el) return;

      const elPosition = el.offsetTop;
      const offset = 50;
      const offsetPosition = elPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-text,
.icons-wrapper {
  line-height: 40px;
}

.icons-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 85px;
  margin-left: 5px;

  .icon-star {
    width: 24px;
    height: 24px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 4px;
  }
}

.logo-text {
  font-size: 30px;
  font-family: serif;
}

.nav-link {
  display: inline-block;
  cursor: pointer;
}

.nav-link.active {
  position: relative;
  color: $color-active !important;

  &:after {
    content: '';
    width: 90%;
    height: 2px;
    background-color: $color-active;
    position: absolute;
    top: 100%;
    left: 5%;
  }
}

.navbar-brand {
  display: flex;
  align-items: center;
  width: calc(100% - 90px);
  word-wrap: break-word;
  white-space: normal;
}
</style>
