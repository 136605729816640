<template>
  <section
    id="scrollspyAbout"
    class="section section-about container">
    <div class="row">
      <div class="col-md-2" />
      <div class="col-md-8">
        <h2 class="section-about-title text-center">
          {{ article.aboutTitle }}
        </h2>
        <p class="text-center">
          {{ article.aboutDescription }}
        </p>
      </div>
      <div class="col-md-2" />
    </div>
    <div class="row">
      <div class="col-md-4" />
      <div class="col-md-4">
        <button
          ref="contactUs"
          type="button"
          class="btn btn-primary ms-auto me-auto button-contact-us"
          @click="scrollContactIntoView">
          {{ $t('global.contact_us') }}
        </button>
      </div>
      <div class="col-md-4" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionAbout',
  computed: {
    article() {
      return {
        aboutTitle: this.$t('section_about.welcome.title'),
        aboutDescription: this.$t('section_about.welcome.description1'),
      };
    },
  },
  methods: {
    scrollContactIntoView() {
      const el = document.getElementById('scrollspyContact');
      if (!el) return;

      window.scrollTo({
        top: el.offsetTop - 50,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.section-about {
  padding-top: 50px;
  padding-bottom: 30px;
}

.section-about-title {
  padding-bottom: 20px;
}

.button-contact-us {
  display: block;
  margin-top: 10px;
}
</style>
