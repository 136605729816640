<template>
  <section class="section section-medjugorje container">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-10 ms-auto me-auto">
        <h3>{{ article.medjugorjeTitle }}</h3>
        <p class="text-justify">
          {{ article.medjugorjeDescription }}
        </p>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-10 ms-auto me-auto">
        <img
          :data-src="require('@/assets/medjugorje.jpeg')"
          alt="Međugorje"
          class="img-fluid lazyload">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionMedjugorje',
  computed: {
    article() {
      return {
        medjugorjeTitle: this.$t('section_medjugorje.title'),
        medjugorjeDescription: this.$t('section_medjugorje.description'),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.section-medjugorje {
  padding-bottom: 40px;
}
</style>
