import Vue from 'vue';
import Vuelidate from 'vuelidate';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueScrollReveal from 'vue-scroll-reveal';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import './libs/fontawesome';
import 'bootstrap';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import i18n from './i18n';

import App from './App.vue';

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(VueScrollReveal, {
  duration: 500,
  delay: 200,
  distance: '50px',
});
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA3aC4xYMiFtiXTA-wdz4Sq-XGes59FPB0',
    installComponents: true,
  },
});

new Vue({
  i18n,
  render: (h) => h(App),
}).$mount('#app');
