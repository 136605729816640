<template>
  <section
    id="scrollspyGallery"
    class="section section-gallery">
    <div class="assets">
      <div class="container">
        <div class="row">
          <div
            v-for="icon in icons"
            :key="icon.icon"
            class="col-md-2 col-6 icon-wrapper">
            <font-awesome-icon
              :icon="icon.icon"
              class="icon" />
            <span>{{ icon.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="images-container">
      <div
        v-for="(image, i) in images.slice(0, isMobile ? 4 : 8)"
        :key="i"
        class="image-wrapper">
        <img
          :data-src="image"
          alt="Pansion milena accomodation Medjugorje"
          class="image lazyload"
          @click="index = i">
      </div>
      <vue-gallery-slideshow
        :images="images"
        :index="index"
        @close="index = null" />
    </div>
  </section>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
  name: 'SectionGallery',
  components: {
    VueGallerySlideshow,
  },
  data() {
    return {
      isMobile: window.innerWidth <= 768,
      index: null,
      images: [
        './gallery/1.jpg',
        './gallery/18.jpg',
        './gallery/19.jpg',
        './gallery/4.jpg',
        './gallery/5.jpg',
        './gallery/6.jpg',
        './gallery/7.jpg',
        './gallery/8.jpg',
        './gallery/9.jpg',
        './gallery/10.jpg',
        './gallery/11.jpg',
        './gallery/12.jpg',
        './gallery/13.jpg',
        './gallery/14.jpg',
        './gallery/15.jpg',
        './gallery/16.jpg',
        './gallery/17.jpg',
        './gallery/2.jpg',
        './gallery/3.jpg',
      ],
    };
  },
  computed: {
    icons() {
      return [{
        icon: 'bed',
        title: this.$t('global.accomodation'),
      }, {
        icon: 'utensils',
        title: this.$t('global.restaurant'),
      }, {
        icon: 'coffee',
        title: this.$t('global.caffe'),
      }, {
        icon: 'plane',
        title: this.$t('global.transfers'),
      }, {
        icon: 'wifi',
        title: this.$t('global.wifi'),
      }, {
        icon: 'car',
        title: this.$t('global.parking'),
      }];
    },
  },
};
</script>

<style lang="scss" scoped>
.section-gallery {
  padding: 40px 0;
}

.assets {
  padding: 50px;
  background-color: $color-active;
  color: #fff;

  .icon {
    color: #fff;
    font-size: 32px;
    margin-bottom: 10px;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.images-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: rgba($color-text-secondary, 0.4);
}

.image-wrapper {
  width: 12.5%;
  height: 200px;
  overflow: hidden;
  flex-shrink: 0;
}

.image {
  display: block;
  height: 100%;
  margin: 0 auto;
  cursor: pointer;
  transition: all .4s ease;

  &:hover {
    transform: scale(1.05);
  }
}

@media screen and (max-width: $break-large) {
  .image-wrapper {
    width: 25%;
  }
}

@media screen and (max-width: $break-mobile) {
  .assets {
    padding: 30px 0;
  }

  .icon-wrapper {
    margin-bottom: 20px;

    &:nth-last-child(1),
    &:nth-last-child(2) {
      margin-bottom: 0;
    }
  }

  .image-wrapper {
    width: 50%;
  }
}
</style>
