const key = 'pansion-filipovic-';

export default {
  setLanguage(val) {
    return localStorage.setItem(`${key}lang`, val || 'hr');
  },
  getLanguage() {
    return localStorage.getItem(`${key}lang`) || 'hr';
  },
};
