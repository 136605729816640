<template>
  <section
    id="scrollspyAccomodation"
    class="section section-accomodation container">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-10 ms-auto me-auto">
        <div class="row">
          <div
            v-for="img in images.slice(0, isMobile ? 2 : 4)"
            :key="img"
            class="img-wrapper col-md-6">
            <img
              :data-src="img"
              class="image lazyload"
              alt="Medjugorje accomodation">
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-10 ms-auto me-auto article-description">
        <h3>{{ article.accomodationTitle }}</h3>
        <p class="text-justify">
          {{ article.accomodationDescription }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionAccomodation',
  data() {
    return {
      isMobile: window.innerWidth <= 768,
      images: [
        './gallery/7.jpg',
        './gallery/4.jpg',
        './gallery/5.jpg',
        './gallery/14.jpg',
      ],
    };
  },
  computed: {
    article() {
      return {
        accomodationTitle: this.$t('global.accomodation').toUpperCase(),
        accomodationDescription: this.$t('section_about.welcome.description2'),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.section-accomodation {
  padding-top: 40px;
  padding-bottom: 40px;
}

.img-wrapper {
  // height: 200px;
  // width: 300px;
  &:nth-child(3),
  &:nth-child(4) {
    margin-top: 20px;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: $break-tablet) {
  .article-description {
    padding-top: 10px;
  }
}

@media screen and (max-width: $break-mobile) {
  .img-wrapper:not(:first-child) {
    margin-top: 20px;
  }
}
</style>
