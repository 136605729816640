<template>
  <header class="carousel-header">
    <div
      id="carouselExampleCaptions"
      class="carousel slide"
      data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button
          v-for="(img, i) in images"
          :key="i"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          :data-bs-slide-to="i"
          :class="{ 'active': i === 0 }"
          :aria-current="i === 0"
          :aria-label="`Slide ${i + 1}`" />
      </div>
      <div class="carousel-inner">
        <div
          v-for="(img, i) in images"
          :key="i"
          class="carousel-item"
          :class="{ 'active': i === 0 }">
          <img
            :src="`./gallery/${img.src}`"
            :alt="img.alt"
            class="landing-image"
            :class="{ 'lazyload': i === 0 }">
          <div class="carousel-caption d-none d-md-block">
            <h5>{{ img.title }}</h5>
            <p>{{ img.description }}</p>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true" />
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true" />
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </header>
</template>

<script>
export default {
  name: 'SectionCarousel',
  data() {
    return {
      images: [{
        src: '16.jpg',
        title: '',
        description: '',
        alt: 'Bračna soba',
      }, {
        src: '15.jpg',
        title: '',
        description: '',
        alt: 'Bračna soba',
      }, {
        src: '18.jpg',
        title: '',
        description: '',
        alt: 'Trokrevetna soba',
      }, {
        src: '19.jpg',
        title: '',
        description: '',
        alt: 'Tuš kabina',
      }, {
        src: '4.jpg',
        title: '',
        description: '',
        alt: 'Pansion MILENA',
      }, {
        src: '6.jpg',
        title: '',
        description: '',
        alt: 'Četverokrevetna soba',
      }, {
        src: '1.jpg',
        title: '',
        description: '',
        alt: 'Pansion RESTORAN',
      }],
    };
  },
};
</script>

<style lang="scss" scoped>
.carousel-item {
  min-height: 500px;
  height: 92vh;
}

.landing-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
