<template>
  <footer class="main-footer bg-dark text-light">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <span>&copy; {{ currentYear }}</span>
          <span class="rights">{{ $t('global.copyrights') }}</span>
        </div>
        <div class="col-md-6" />
        <div class="col-md-3">
          <address class="address">
            <a :href="`mailto:${contact.email1}`">
              {{ contact.email1 }}
            </a><br>
            <a :href="`mailto:${contact.email2}`">
              {{ contact.email2 }}
            </a><br>
            <a :href="`tel:${contact.mob}`">
              {{ contact.mob }}
            </a><br>
          </address>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'MainFooter',
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    contact() {
      return {
        mob: '+387 63 325 141',
        email1: 'pansion-filipovic@net.hr',
        email2: 'filipovicmario4@gmail.com',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.main-footer {
  padding: 20px 0;
  font-size: 13px;
}

.rights {
  padding-left: 10px;
}

.address {
  margin-bottom: 0;

  a:not(:hover) {
    color: #f8f9fa;
  }
}
</style>
