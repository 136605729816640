<template>
  <div class="app">
    <MainHeader />
    <SectionCarousel />
    <SectionAbout v-scroll-reveal />
    <SectionAccomodation v-scroll-reveal />
    <SectionMedjugorje v-scroll-reveal />
    <SectionGallery />
    <SectionContact v-scroll-reveal />
    <SectionMap v-scroll-reveal />
    <MainFooter />
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue';
import SectionCarousel from '@/components/SectionCarousel.vue';
import SectionAbout from '@/components/SectionAbout.vue';
import SectionAccomodation from '@/components/SectionAccomodation.vue';
import SectionMedjugorje from '@/components/SectionMedjugorje.vue';
import SectionGallery from '@/components/SectionGallery.vue';
import SectionContact from '@/components/SectionContact.vue';
import SectionMap from '@/components/SectionMap.vue';
import MainFooter from '@/components/MainFooter.vue';

export default {
  name: 'App',
  components: {
    MainHeader,
    SectionCarousel,
    SectionAbout,
    SectionAccomodation,
    SectionMedjugorje,
    SectionGallery,
    SectionContact,
    SectionMap,
    MainFooter,
  },
};
</script>

<style lang="scss">
@import './scss/main';
</style>
