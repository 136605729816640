var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section section-contact bg-light",attrs:{"id":"scrollspyContact"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('h3',[_vm._v(_vm._s(_vm.contact.title)+":")]),_c('address',{staticClass:"address"},[_c('b',[_vm._v(_vm._s(_vm.$t('global.address'))+":")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.contact.address))]),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$t('global.phone'))+":")]),_vm._v(" "),_c('a',{attrs:{"href":`tel:${_vm.contact.phone}`}},[_vm._v(" "+_vm._s(_vm.contact.phone)+" ")]),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$t('global.mob'))+":")]),_vm._v(" "),_c('a',{attrs:{"href":`tel:${_vm.contact.mob}`}},[_vm._v(" "+_vm._s(_vm.contact.mob)+" ")]),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$t('global.email'))+":")]),_vm._v(" "),_c('a',{attrs:{"href":`mailto:${_vm.contact.email1}`}},[_vm._v(" "+_vm._s(_vm.contact.email1)+" ")]),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$t('global.email'))+":")]),_vm._v(" "),_c('a',{attrs:{"href":`mailto:${_vm.contact.email2}`}},[_vm._v(" "+_vm._s(_vm.contact.email2)+" ")]),_c('br')])]),_c('div',{staticClass:"col-md-6"},[_c('form',{staticClass:"row g-2 form"},[_c('Spinner',{attrs:{"loading":_vm.loading}}),_c('div',{staticClass:"form-floating mb-3 col-md-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],ref:"name",staticClass:"form-control",class:{
                'is-invalid': _vm.$v.name.$error,
                'is-valid': _vm.$v.name.$dirty && !_vm.$v.name.$error,
              },attrs:{"id":"floatingName","type":"text","placeholder":_vm.$t('global.your_name'),"required":""},domProps:{"value":(_vm.name)},on:{"input":[function($event){if($event.target.composing)return;_vm.name=$event.target.value},function($event){return _vm.$v.name.$touch()}]}}),_c('label',{attrs:{"for":"floatingName"}},[_vm._v(_vm._s(_vm.$t('global.your_name')))])]),_c('div',{staticClass:"form-floating mb-3 col-md-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],ref:"email",staticClass:"form-control",class:{
                'is-invalid': _vm.$v.email.$error,
                'is-valid': _vm.$v.email.$dirty && !_vm.$v.email.$error,
              },attrs:{"id":"floatingEmail","type":"email","placeholder":_vm.$t('global.your_email'),"required":""},domProps:{"value":(_vm.email)},on:{"input":[function($event){if($event.target.composing)return;_vm.email=$event.target.value},function($event){return _vm.$v.email.$touch()}]}}),_c('label',{attrs:{"for":"floatingEmail"}},[_vm._v(_vm._s(_vm.$t('global.your_email')))])]),_c('div',{staticClass:"form-floating mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.title),expression:"title"}],ref:"title",staticClass:"form-control",class:{
                'is-invalid': _vm.$v.title.$error,
                'is-valid': _vm.$v.title.$dirty && !_vm.$v.title.$error,
              },attrs:{"id":"floatingTitle","type":"text","placeholder":_vm.$t('global.your_title'),"required":""},domProps:{"value":(_vm.title)},on:{"input":[function($event){if($event.target.composing)return;_vm.title=$event.target.value},function($event){return _vm.$v.title.$touch()}]}}),_c('label',{attrs:{"for":"floatingTitle"}},[_vm._v(_vm._s(_vm.$t('global.your_title')))])]),_c('div',{staticClass:"form-floating mb-3"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],ref:"text",staticClass:"form-control",class:{
                'is-invalid': _vm.$v.text.$error,
                'is-valid': _vm.$v.text.$dirty && !_vm.$v.text.$error,
              },attrs:{"id":"floatingComment","rows":5,"placeholder":_vm.$t('global.your_comment'),"required":""},domProps:{"value":(_vm.text)},on:{"input":[function($event){if($event.target.composing)return;_vm.text=$event.target.value},function($event){return _vm.$v.text.$touch()}]}}),_vm._v(" "),_c('label',{attrs:{"for":"floatingComment"}},[_vm._v(_vm._s(_vm.$t('global.your_comment')))])]),(_vm.successMsg)?_c('div',{staticClass:"alert alert-success"},[_vm._v(" "+_vm._s(_vm.successMsg)+" ")]):_vm._e(),(_vm.errorMsg)?_c('div',{staticClass:"alert alert-danger"},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e(),_c('button',{staticClass:"btn btn-primary col-md-3",attrs:{"type":"submit"},on:{"click":_vm.sendMail}},[_vm._v(" "+_vm._s(_vm.$t('global.submit'))+" ")])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }