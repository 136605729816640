<template>
  <section
    id="scrollspyContact"
    class="section section-contact bg-light">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h3>{{ contact.title }}:</h3>
          <!-- <p>{{ contact.description }}</p> -->
          <address class="address">
            <b>{{ $t('global.address') }}:</b> <span>{{ contact.address }}</span><br>
            <b>{{ $t('global.phone') }}:</b> <a :href="`tel:${contact.phone}`">
              {{ contact.phone }}
            </a><br>
            <b>{{ $t('global.mob') }}:</b> <a :href="`tel:${contact.mob}`">
              {{ contact.mob }}
            </a><br>
            <b>{{ $t('global.email') }}:</b> <a :href="`mailto:${contact.email1}`">
              {{ contact.email1 }}
            </a><br>
            <b>{{ $t('global.email') }}:</b> <a :href="`mailto:${contact.email2}`">
              {{ contact.email2 }}
            </a><br>
          </address>
        </div>
        <div class="col-md-6">
          <form class="row g-2 form">
            <Spinner :loading="loading" />
            <div class="form-floating mb-3 col-md-6">
              <input
                id="floatingName"
                ref="name"
                v-model="name"
                type="text"
                :placeholder="$t('global.your_name')"
                class="form-control"
                :class=" {
                  'is-invalid': $v.name.$error,
                  'is-valid': $v.name.$dirty && !$v.name.$error,
                }"
                required
                @input="$v.name.$touch()">
              <label for="floatingName">{{ $t('global.your_name') }}</label>
            </div>
            <div class="form-floating mb-3 col-md-6">
              <input
                id="floatingEmail"
                ref="email"
                v-model="email"
                type="email"
                :placeholder="$t('global.your_email')"
                class="form-control"
                :class=" {
                  'is-invalid': $v.email.$error,
                  'is-valid': $v.email.$dirty && !$v.email.$error,
                }"
                required
                @input="$v.email.$touch()">
              <label for="floatingEmail">{{ $t('global.your_email') }}</label>
            </div>
            <div class="form-floating mb-3">
              <input
                id="floatingTitle"
                ref="title"
                v-model="title"
                type="text"
                :placeholder="$t('global.your_title')"
                class="form-control"
                :class=" {
                  'is-invalid': $v.title.$error,
                  'is-valid': $v.title.$dirty && !$v.title.$error,
                }"
                required
                @input="$v.title.$touch()">
              <label for="floatingTitle">{{ $t('global.your_title') }}</label>
            </div>
            <div class="form-floating mb-3">
              <textarea
                id="floatingComment"
                ref="text"
                v-model="text"
                class="form-control"
                :rows="5"
                :placeholder="$t('global.your_comment')"
                :class=" {
                  'is-invalid': $v.text.$error,
                  'is-valid': $v.text.$dirty && !$v.text.$error,
                }"
                required
                @input="$v.text.$touch()" />
              <label for="floatingComment">{{ $t('global.your_comment') }}</label>
            </div>
            <div
              v-if="successMsg"
              class="alert alert-success">
              {{ successMsg }}
            </div>
            <div
              v-if="errorMsg"
              class="alert alert-danger">
              {{ errorMsg }}
            </div>
            <button
              type="submit"
              class="btn btn-primary col-md-3"
              @click="sendMail">
              {{ $t('global.submit') }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
  email,
} from 'vuelidate/lib/validators';
import emailjs from '@emailjs/browser';

import Spinner from '@/components/shared/Spinner.vue';

export default {
  name: 'SectionContact',
  components: {
    Spinner,
  },
  mixins: [validationMixin],
  data() {
    return {
      name: '',
      email: '',
      title: '',
      text: '',
      loading: false,
      successMsg: '',
      errorMsg: '',
    };
  },
  validations: {
    name: { required },
    email: { required, email },
    title: { required },
    text: { required },
  },
  computed: {
    contact() {
      return {
        title: this.$t('section_contact.title'),
        description: this.$t('section_contact.description'),
        address: 'Franjevačkih mučenika 53, 88266 Međugorje, BiH',
        phone: '+387 36 655 163',
        mob: '+387 63 325 141',
        email1: 'pansion-filipovic@net.hr',
        email2: 'filipovicmario4@gmail.com',
      };
    },
  },
  methods: {
    isValid() {
      Object.keys(this.$refs).forEach((key) => {
        this.$v[key].$touch();
      });

      return !this.$v.$invalid && !this.$v.$error;
    },
    sendMail(e) {
      e.preventDefault();
      if (!this.isValid()) return;
      if (this.loading) return;

      const serviceID = 'service_p85cm3v';
      const templateID = 'template_naeqqwq';
      const publicKey = '3W38R9JmV7t9qe1hZ';

      const emailjsParams = {
        name: this.name,
        email: this.email,
        title: this.title,
        text: this.text,
      };

      // NOTE: Old approach that was using smtpjs.com
      // Host: 'smtp.gmail.com'
      // Username: 'pansionfilipovic.medjugorje@gmail.com'
      // Password: '12345678Abcd,'
      this.loading = true;
      emailjs
        .send(serviceID, templateID, emailjsParams, publicKey)
        .then(() => {
          this.successMsg = this.$t('global.msg_sent');
          this.resetFormFields();
        }, (error) => {
          if (error) {
            this.errorMsg = this.$t('global.msg_not_sent');
          }
        })
        .catch(() => {
          this.errorMsg = this.$t('global.msg_not_sent');
        })
        .finally(() => {
          this.loading = false;
          setTimeout(this.resetInfoMsg, 5000);
        });
    },
    resetFormFields() {
      this.name = '';
      this.email = '';
      this.title = '';
      this.text = '';
      this.$v.$reset();
    },
    resetInfoMsg() {
      this.successMsg = '';
      this.errorMsg = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.section-contact {
  padding-top: 60px;
  padding-bottom: 60px;
}

.address {
  line-height: 28px;
  font-size: 14px;
}

#floatingComment {
  height: 150px;
}

.form {
  position: relative;
}
</style>
