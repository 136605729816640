import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ls from '@/services/ls';

import hr from '@/locales/hr.json';
import en from '@/locales/en.json';
import de from '@/locales/de.json';
import it from '@/locales/it.json';

Vue.use(VueI18n);

const lang = ls.getLanguage() || navigator.language.split('-')[0] || 'hr';
const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'hr',
  messages: {
    hr, en, de, it,
  },
});

export default i18n;
